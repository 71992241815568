import { DashboardExpComponent } from './styles/DashboardExpComponent';
import { useState, useEffect } from 'react';
import membersProgressService from '../../../services/adminService';
import {
  Tooltip,
  Legend,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  BarChart,
  Bar,
} from 'recharts';
import PieChartComponent from '../utils/PieChartComponent';

const Experiencia = () => {
  const [loading, setLoading] = useState(false);
  const [volunteers, setVolunteers] = useState({});
  const [groupedCategories, setGroupedCategories] = useState([]);
  const [data, setData] = useState([]);
  const [perAreaData, setPerAreaData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [allAreas, setAllAreas] = useState([]);
  const [areLeaders, setAreLeaders] = useState({});

  useEffect(() => {
    setLoading(true);
    let volun = [];
    membersProgressService
      .getCrecerVsVolunteerProgress({
        step: 'vida',
        startDate: '2024-06-01',
        endDate: '2024-12-31',
        classesAttended: '16',
      })
      .then((response) => {})
      .finally(() => {
        setLoading(false);
      });

    membersProgressService
      .getAllVolunteersByGuests({ keyword: '', volunteerRole: '', volunteerSection: '' })
      .then(async (response) => {
        const volunteers = response.data.volunteers;
        grupedByAreas(volunteers);
        volunteersMore(volunteers);
        volun = volunteers;
      })
      .finally(() => {
        setLoading(false);
      });

    membersProgressService.getVolunteersLeading({}).then((resp) => {
      const leaders = resp.data.groups?.length;
      const notLeaders = volun.length - leaders;
      const leaderData = {
        title: `voluntarios en liderazgo`,
        data: [
          {
            name: 'Lideres',
            value: leaders,
            percentage: volun.length > 0 ? ((leaders / volun.length) * 100).toFixed(1) : '0',
          },
          {
            name: 'No Lideres',
            value: notLeaders,
            percentage: volun.length > 0 ? ((notLeaders / volun.length) * 100).toFixed(1) : '0',
          },
        ],
      };
      setAreLeaders(leaderData);
    });
  }, []);

  const grupedByAreas = (volunteers) => {
    const totalVolunteers = volunteers.length;
    const groupedByServiceArea = volunteers.reduce((acc, volunteer) => {
      volunteer.areasOfService.forEach(({ serviceArea }) => {
        if (!acc[serviceArea]) {
          acc[serviceArea] = [];
        }
        acc[serviceArea].push(volunteer);
      });
      return acc;
    }, {});

    const groupedDataByArea = Object.entries(groupedByServiceArea)
      .map(([area, volunteers]) => ({
        name: area,
        cantidad: volunteers.length,
        percentage: ((volunteers.length / totalVolunteers) * 100).toFixed(1),
      }))
      .sort((a, b) => b.cantidad - a.cantidad);

    setAllAreas(groupedDataByArea);

    groupedByServiceArea['Todos'] = volunteers;
    setVolunteers(groupedByServiceArea);
    setGroupedCategories(Object.keys(groupedByServiceArea));
  };

  const volunteersMore = (volunteers) => {
    const vo = volunteers;
    const totalVolunteers = volunteers.length;
    const groupedByAreas = vo.reduce((acc, volunteer) => {
      const numAreas = volunteer.totalAreasOfService;
      if (!acc[numAreas]) {
        acc[numAreas] = [];
      }
      acc[numAreas].push(volunteer);
      return acc;
    }, {});

    const volunteersPerAreaCount = Object.entries(groupedByAreas).map(([numAreas, volunteers]) => ({
      Cantidad_Areas: parseInt(numAreas),
      Voluntarios: volunteers.length,
      percentage: ((volunteers.length / totalVolunteers) * 100).toFixed(1),
    }));
    setPerAreaData(volunteersPerAreaCount);
  };

  const filterVolunteers = (volunteers) => {
    if (!Array.isArray(volunteers)) {
      console.error('volunteers debe ser un array');
      setLoading(false);
      return;
    }
    const menCount = volunteers.filter((v) => v.gender === 'Masculino').length;
    const womenCount = volunteers.filter((v) => v.gender === 'Femenino').length;
    const total = menCount + womenCount;

    const genderData = {
      title: `Total de voluntarios: ${total}`,
      data: [
        {
          name: 'Hombres',
          value: menCount,
          percentage: total > 0 ? ((menCount / total) * 100).toFixed(1) : '0',
        },
        {
          name: 'Mujeres',
          value: womenCount,
          percentage: total > 0 ? ((womenCount / total) * 100).toFixed(1) : '0',
        },
      ],
    };
    return genderData;
  };

  const filterVolunteersByAgeSegment = (volunteers) => {
    if (!Array.isArray(volunteers)) {
      console.error('volunteers debe ser un array');
      setLoading(false);
      return;
    }

    const ageSegments = volunteers.reduce((acc, volunteer) => {
      const { ageSegment } = volunteer;
      if (!acc[ageSegment]) {
        acc[ageSegment] = 0;
      }
      acc[ageSegment] += 1;
      return acc;
    }, {});

    const ageSegmentData = {
      title: 'Segmentación por rango de edad',
      data: Object.entries(ageSegments).map(([segment, count]) => ({
        name: segment,
        value: count,
        percentage: ((count / volunteers.length) * 100).toFixed(1),
      })),
    };
    return ageSegmentData;
  };

  const handleVolunteersChange = (area) => {
    const selectedVolunteers = volunteers[area];
    if (selectedVolunteers) {
      const gender = filterVolunteers(selectedVolunteers);
      const ageSegment = filterVolunteersByAgeSegment(selectedVolunteers);
      setData([gender, ageSegment]);
    }
  };

  const handleChange = (e) => {
    setSelectedCategory(e.target.value);
    handleVolunteersChange(e.target.value);
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div className="tooltipContainer">
          <p>{`${data.value} (${data.percentage}%)`}</p>
        </div>
      );
    }
    return null;
  };

  const CustomTooltipOther = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;

      return (
        <div className="tooltipContainer">
          {Object.entries(data).map(([key, value]) => (
            <p key={key}>
              {key === 'percentage' ? 'Porcentaje' : key === 'name' ? 'Área' : key} :
              {typeof value === 'object' ? JSON.stringify(value) : value}
            </p>
          ))}
        </div>
      );
    }
    return null;
  };

  return (
    <DashboardExpComponent>
      {loading ? (
        <div className="containerSpinner">
          <div className="spinner"></div>
        </div>
      ) : (
        <>
          <h2>Experiencia</h2>
          <div className="tab-content">
            <div>
              <div className="select-container">
                <select onChange={handleChange} className="inputs">
                  <option value="">Ninguna</option>
                  {groupedCategories.map((category, index) => (
                    <option key={index} value={category}>
                      {category}
                    </option>
                  ))}
                </select>
              </div>

              {loading ? (
                <>
                  <div className="containerSpinner">
                    <div className="spinner"></div>
                  </div>
                </>
              ) : (
                <>
                  <div className="charts-container">
                    {data.map((item, index) => (
                      <div key={index} className="chart-item">
                        <div>
                          <PieChartComponent
                            title={item.title}
                            data={item.data}
                            width={600}
                            height={370}
                            outerRadius={100}
                            nameKey="name"
                            customTooltip={<CustomTooltip />}
                          />
                        </div>
                      </div>
                    ))}
                    {selectedCategory === 'Todos' && (
                      <>
                        <div>
                          <PieChartComponent
                            title={areLeaders.title}
                            data={areLeaders.data}
                            width={600}
                            height={370}
                            outerRadius={100}
                            nameKey="name"
                            customTooltip={<CustomTooltip />}
                          />
                        </div>
                        <div>
                          <h3 className="subTitle">Distribución por área</h3>
                          <BarChart width={700} height={400} data={allAreas} layout="vertical">
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis type="number" />
                            <YAxis type="category" dataKey="name" width={150} />
                            <Tooltip content={<CustomTooltipOther />} />
                            <Legend />
                            <Bar dataKey="cantidad" fill="#8884d8" />
                          </BarChart>
                        </div>

                        <div>
                          <h3 className="subTitle">Numero de áreas por Voluntario</h3>
                          <LineChart width={600} height={400} data={perAreaData}>
                            <Line type="monotone" dataKey="Voluntarios" stroke="#8884d8" />
                            <Legend />
                            <Tooltip content={<CustomTooltipOther />} />
                            <CartesianGrid strokeDasharray="3 3" />
                            <YAxis />
                            <XAxis dataKey="Cantidad_Areas" />
                          </LineChart>
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </DashboardExpComponent>
  );
};

export default Experiencia;
