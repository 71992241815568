import React from 'react';
import Form from '../../../components/form/Form';
import Swal from 'sweetalert2';
import membersForms from '../../../services/membersForms';
import { Button } from 'semantic-ui-react';
import { chPerm, errorInput } from '../../../components/helpers';
import ReactPlayer from 'react-player/youtube';
import { habeasData } from '../../../components/helpers/habeasData';
import ReCAPTCHA from 'react-google-recaptcha';

const NewMembersForm = (props) => {
  const [Attendant, set_Attendant] = React.useState({});
  const [errorInputs, set_errorInputs] = React.useState({});
  const [sent, set_sent] = React.useState(false);
  const [sending, set_sending] = React.useState(false);

  const { href } = window.location;
  const virtualFill = href.includes('casa');

  const verifyForm = () => {
    let mandatoryInputs = ['name', 'lastName', 'identification', 'phone', 'birthdate'];

    let errorInputs = {};

    mandatoryInputs.forEach((input) => {
      errorInputs = errorInput({
        errorInputs,
        input: { name: input, value: Attendant[input] },
      });
    });

    if (Attendant['identification'] && Attendant['identification'].length <= 5) {
      errorInputs = errorInput({
        errorInputs,
        input: {
          name: 'identification',
          value: Attendant['identification'].length >= 5,
        },
      });
    }

    if (Attendant['phone'] && Attendant['phone'].length <= 10) {
      errorInputs = errorInput({
        errorInputs,
        input: {
          name: 'phone',
          value: Attendant['phone'].length >= 10,
        },
      });
    }

    set_errorInputs(errorInputs);
    return Object.values(errorInputs).includes(true);
  };

  let sendForm = (e) => {
    e.preventDefault();
    if (verifyForm()) return;
    set_sending(true);
    let body = { ...Attendant, virtualFill };
    membersForms
      .newMembers(body)
      .then((response) => {
        set_sending(false);
        if (response.data.success) {
          set_sent(true);
          Swal.fire('¡Se ha enviado correctamente!');
        } else {
          Swal.fire('Hubo un error, por favor intenta mas tarde.');
        }
      })
      .catch((error) => {
        set_sending(false);
        Swal.fire('Hubo un error, por favor intenta mas tarde.');
      });
  };

  const handleInputs = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    set_Attendant({ ...Attendant, [name]: value });
  };

  return (
    <div className="new-member">
      <form className="Registration__form">
        {!sent ? (
          <>
            <h1>
              Bienvenid{Attendant.gender === 'Femenino' ? 'a' : 'o'} {Attendant.name}
            </h1>
            {virtualFill && (
              <div className="welcome-video">
                <ReactPlayer url={'https://youtu.be/VFubyPwrUFYs'} />
              </div>
            )}
            <h2>¡Queremos conocerte!</h2>
            {Object.values(errorInputs).includes(true) && (
              <h4 style={{ color: 'red' }}>Por favor verifica los campos en rojo.</h4>
            )}
            <Form
              showBirthdate
              Attendant={Attendant}
              handleInputs={handleInputs}
              errorInputs={errorInputs}
            />
            {habeasData}
            {Object.values(errorInputs).includes(true) && (
              <h4 style={{ color: 'red' }}>Por favor verifica los campos en rojo.</h4>
            )}
            {!chPerm(['newMembers']) && (
              <div className={`captcha ${errorInputs.captcha ? 'error' : ''}`}>
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA}
                  onChange={(value) => set_Attendant({ ...Attendant, captcha: value })}
                />
              </div>
            )}
            <Button onClick={(e) => sendForm(e)} primary disabled={sending}>
              Enviar
            </Button>
          </>
        ) : (
          <>
            <h1>¡Gracias {Attendant.name}!</h1>
            <p>Estaremos en contacto.</p>
            {virtualFill && <p>En máximo 30 minutos el libro estará llegando a tu correo.</p>}
          </>
        )}
      </form>
    </div>
  );
};

export default NewMembersForm;
