import React from 'react';
import Form from '../../form/Form';
import Swal from 'sweetalert2';
import groupService from '../../../services/groupService';
import { Button, Input, Select, TextArea } from 'semantic-ui-react';
import { clickableStyles, errorInput, scrollUp, textArea } from '../../helpers';
import { habeasData } from '../../helpers/habeasData';
import styled from '@emotion/styled';
import ReCAPTCHA from 'react-google-recaptcha';
import { formQuestionList, newLeadersQuestions, endQuestionary } from './newLeadersQuestions';
import ReactMarkdown from 'react-markdown';

const FormComp = styled('div')`
  width: 80%;
  .Registration__form {
    background: white;
    text-align: start;
  }
  .form-prospect {
    text-align: center;
    p {
      text-align: justify;
    }
  }
  .richText {
    max-width: 500px;
    margin: 20px auto;
    padding: 20px 0;
    text-align: justify;
    p {
      margin: 0;
    }
  }
  p.disciple-name {
    font-weight: bold;
    text-align: center;
    font-size: 32px;
  }
  h2 {
    margin: 30px 0;
  }
  label {
    margin: 10px 0 0 0;
  }
  .error {
    color: red;
  }
  #transversal {
    textarea {
      &.error {
        border: 1px solid red;
        background-color: #fffcfc;
      }
    }
  }
  .ui.button {
    margin: 20px 10px 0px 10px;
  }
  @media screen and (max-width: 950px) {
    width: 90%;
  }
  @media screen and (max-width: 750px) {
    width: 100%;
  }
`;

const NewLeadersForm = () => {
  const [guests, set_guests] = React.useState({});
  const [inputs, set_Inputs] = React.useState({});
  const [errorInputs, set_errorInputs] = React.useState({});
  const [errorGuestsInputs, set_errorGuestsInputs] = React.useState({});
  const [step, set_step] = React.useState(0);
  const [sent, set_sent] = React.useState(false);
  const [sending, set_sending] = React.useState(false);
  const [showCaptcha, set_showCaptcha] = React.useState(true);
  const [captchaLoaded, set_captchaLoaded] = React.useState(true);

  const reloadCaptcha = () => {
    set_showCaptcha(false);
    setTimeout(() => set_showCaptcha(true), 500);
  };

  const currentFormQuestions = newLeadersQuestions?.[inputs?.formQuestion]?.[step];

  const verifyForm = () => {
    let errorInputs = {};

    let mandatoryInputs = Object.values(currentFormQuestions)
      ?.map((a) => a.id)
      .filter((a) => !!a);

    mandatoryInputs.forEach((input) => {
      errorInputs = errorInput({
        errorInputs,
        input: { name: input, value: inputs[input] },
      });
    });

    const conditionalQuestions = mandatoryInputs?.filter((a) => a.includes('conditional'));

    conditionalQuestions.forEach((input) => {
      if (inputs[input] === 'No') {
        errorInputs = { ...errorInputs, not_allowed: true };
      }
    });
    set_errorInputs(errorInputs);

    if (errorInputs?.not_allowed) set_sent(true);

    let errorGuestsInputs = {};
    let mandatoryGuestsInputs = [];
    if (Object.values(currentFormQuestions)?.some((el) => el?.type?.includes('form'))) {
      if (inputs?.info_couple_group || inputs?.formQuestion === 'Couple_Seminaries') {
        mandatoryGuestsInputs = {
          maleGuest: ['name', 'lastName', 'identification', 'phone'],
          femaleGuest: ['name', 'lastName', 'identification', 'phone'],
        };
      } else {
        mandatoryGuestsInputs = {
          guest: ['name', 'lastName', 'identification', 'phone'],
        };
      }
    }

    Object.entries(mandatoryGuestsInputs).forEach(([form, guestInputs]) => {
      guestInputs.forEach((input) => {
        errorGuestsInputs[form] = errorInput({
          errorInputs: errorGuestsInputs[form],
          input: { name: input, value: guests?.[form]?.[input] },
        });
      });
    });
    set_errorGuestsInputs(errorGuestsInputs);

    const guestsInputsFlags =
      Object.entries(errorGuestsInputs)?.reduce(
        (acc, [key, value]) => ({ ...acc, [key]: Object.values(value)?.some(Boolean) }),
        {}
      ) || {};

    return Object.values({
      ...errorInputs,
      ...guestsInputsFlags,
    }).includes(true);
  };

  let sendForm = (e) => {
    e.preventDefault();
    set_sending(true);

    let body = {
      ...guests,
      inputs,
      captcha: inputs.captcha,
    };
    groupService
      .newLeaderForm(body)
      .then((response) => {
        set_sending(false);
        if (response.data.success) {
          set_sent(true);
        }
        Swal.fire(response?.data?.message);
      })
      .catch((error) => {
        set_sending(false);
        Swal.fire(error?.response?.data?.message);
      });
  };

  const handleGuests = (event, person) =>
    set_guests({
      ...guests,
      [person]: { ...(guests[person] || {}), [event.target.name]: event.target.value },
    });

  const handleInputs = (event) =>
    set_Inputs({ ...inputs, [event.target.name]: event.target.value });

  const renderQuestions = (questions) =>
    questions.map((question) => {
      if (question.type === 'title') {
        return <h2>{question?.text}</h2>;
      }
      if (question.type === 'text') {
        return <p>{question?.text}</p>;
      }
      if (question.type === 'richText') {
        return (
          <div className="richText">
            <ReactMarkdown className="line-break">{question?.text}</ReactMarkdown>
          </div>
        );
      }
      if (question.type === 'select') {
        return (
          <div>
            <label
              style={{ display: 'block' }}
              className={`${errorInputs[question.id] ? 'error' : ''}`}
              htmlFor={question.id}
            >
              {question.text}
            </label>
            <Select
              options={
                question.optionsObjects ??
                question.options.map((option) => ({
                  key: option,
                  text: option,
                  value: option,
                }))
              }
              id={question.id}
              label={question.text}
              name={question.id}
              className={`${errorInputs[question.id] ? 'error' : ''}`}
              disabled={sending}
              value={inputs[question.id]}
              onChange={(a, { value }) => set_Inputs({ ...inputs, [question.id]: value })}
              loading={sending}
            />
          </div>
        );
      }
      if (question.type === 'textArea') {
        return (
          <div>
            <label
              style={{ display: 'block' }}
              className={`${errorInputs[question.id] ? 'error' : ''}`}
              htmlFor={question.id}
            >
              {question.text}
            </label>
            {textArea({
              TextArea,
              label: question.text,
              name: question.id,
              disabled: sending,
              value: inputs[question.id],
              handler: (event) => handleInputs(event),
              loading: sending,
              errorInputs,
            })}
          </div>
        );
      }
      if (question.type === 'input') {
        return (
          <div>
            <label
              style={{ display: 'block' }}
              className={`${errorInputs[question.id] ? 'error' : ''}`}
              htmlFor={question.id}
            >
              {question.text}
            </label>
            <Input
              name={question.id}
              placeholder={question.text}
              className={`${errorInputs[question.id] ? 'error' : ''}`}
              disabled={sending}
              type={question.kind === 'number' ? 'number' : 'text'}
              value={inputs[question.id]}
              onChange={(event) => handleInputs(event)}
              loading={sending}
            />
          </div>
        );
      }
      if (question.type === 'form') {
        return (
          <>
            <h2>{question?.text}</h2>
            <Form
              showGender
              showBirthdate
              Attendant={guests?.guest}
              errorInputs={errorGuestsInputs?.guest}
              handleInputs={(event) => handleGuests(event, 'guest')}
            />
          </>
        );
      }
      if (question.type === 'form_male') {
        return (
          <>
            <h2>{question?.text}</h2>
            <Form
              Attendant={guests?.maleGuest}
              errorInputs={errorGuestsInputs?.maleGuest}
              handleInputs={(event) => handleGuests(event, 'maleGuest')}
            />
          </>
        );
      }
      if (question.type === 'form_female') {
        return (
          <>
            <h2>{question?.text}</h2>
            <Form
              Attendant={guests?.femaleGuest}
              handleInputs={(event) => handleGuests(event, 'femaleGuest')}
              errorInputs={errorGuestsInputs?.femaleGuest}
            />
          </>
        );
      }
      if (question.type === 'endInfo') {
        return (
          <div>
            {habeasData}
            <div className={`captcha ${errorInputs.captcha ? 'error' : ''}`}>
              {showCaptcha && (
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA}
                  onChange={(value) => set_Inputs({ ...inputs, captcha: value })}
                  asyncScriptOnLoad={() => set_captchaLoaded(false)}
                />
              )}
              {!captchaLoaded && showCaptcha && (
                <p style={clickableStyles} onClick={reloadCaptcha}>
                  Si no carga el captcha, haz clic aqui para obtener uno nuevo.
                </p>
              )}
            </div>
          </div>
        );
      }
      if (question.type === 'prevButton') {
        return (
          <Button onClick={(e) => changeStep(e, 'prev')} disabled={sending}>
            Volver
          </Button>
        );
      }
      if (question.type === 'nextButton') {
        return (
          <Button onClick={(e) => changeStep(e, 'next')} primary disabled={sending}>
            Siguiente
          </Button>
        );
      }
      if (question.type === 'send') {
        return (
          <Button onClick={(e) => sendForm(e)} primary disabled={sending}>
            Enviar
          </Button>
        );
      }
      return null;
    });
  const changeStep = (e, move) => {
    e.preventDefault();
    if (move === 'next') {
      scrollUp();
      if (verifyForm()) return;
      set_step(step + 1);
    } else {
      set_step(step - 1);
    }
  };

  if (sent) {
    return (
      <FormComp>
        <div className="Registration__form">
          {renderQuestions(endQuestionary(inputs.formQuestion, !errorInputs?.not_allowed))}
        </div>
      </FormComp>
    );
  }
  if (inputs.formQuestion) {
    return (
      <FormComp>
        <div className="Registration__form">
          <Button
            onClick={() => {
              set_Inputs({});
              set_step(0);
            }}
            style={{ margin: '20px 0' }}
            disabled={sending}
          >
            Volver a comenzar
          </Button>
          <div className="form-prospect">
            {renderQuestions(currentFormQuestions)}
            <h4>Todos los campos son obligatorios.</h4>
          </div>
        </div>
      </FormComp>
    );
  }
  return (
    <FormComp>
      <div className="Registration__form">
        <div className="form-prospect">{renderQuestions(formQuestionList)}</div>
      </div>
    </FormComp>
  );
};

export default NewLeadersForm;
