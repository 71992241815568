import React from 'react';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import AttendanceModal from './AttendanceModal';
import authService from '../../services/auth';
import seminaryForms from '../../services/seminaryService';

const SeminaryCheckin = (props) => {
  const { Seminaries } = props.Attendant;
  const [loadedData, set_loadedData] = React.useState(null);
  const [loading, set_loading] = React.useState(false);
  const [openModal, set_openModal] = React.useState('');
  const [changedDate, set_changedDate] = React.useState(new Date());
  const currentUser = authService.getCurrentUser();

  const contentSeminaries = (name) => Seminaries?.filter((s) => s.seminary === name);

  const seminaryEvent = [
    {
      title: `Para Siempre`,
      canCreate: currentUser.profile,
      content: contentSeminaries(`ParaSiempre`),
      event: `ParaSiempre`,
    },
    {
      title: `Proyecto Matrimonio`,
      canCreate: currentUser.profile,
      content: contentSeminaries(`ProyectoMatrimonio`),
      event: `ProyectoMatrimonio`,
    },
    {
      title: `Relaciones Sanas`,
      canCreate: currentUser.profile,
      content: contentSeminaries(`RelacionesSanas`),
      event: `RelacionesSanas`,
    },
    {
      title: `Transformacion Empresarial`,
      canCreate: currentUser.profile,
      content: contentSeminaries(`TransformacionEmpresarial`),
      event: `TransformacionEmpresarial`,
    },
    {
      title: `Escuela de Heroes`,
      canCreate: currentUser.profile,
      content: contentSeminaries(`EscueladeHeroes`),
      event: `EscueladeHeroes`,
    },
  ];

  React.useEffect(() => {
    setTimeout(() => {
      set_loadedData(null);
    }, 5000);
  }, [loadedData]);

  function updateAttendance({ classNumber, event, today }) {
    let body = {
      guest_id: props.Attendant.id,
      date: changedDate || today,
      classNumber,
      event,
    };
    set_loading(true);

    seminaryForms
      .setSeminaryAttendance(body)
      .then((response) => {
        if (response.data.success) {
          props.set_Attendant(response.data.Attendant);
          set_loadedData('');
          set_loading(false);
        }
      })
      .catch((error) => {
        Swal.fire('Hubo un error');
        set_loading(false);
        set_loadedData('');
      });
  }

  async function createAttendance({ identification = props.Attendant.identification, event }) {
    let body = {
      identification,
      event,
    };
    set_loading(true);

    await seminaryForms
      .createAttendance(body)
      .then((response) => {
        if (response.data.success) {
          props.set_Attendant(response.data.Attendant);
          set_loadedData(response.data.message);
        }
        set_loading(false);
      })
      .catch((error) => {
        set_loading(false);
        set_loadedData(error.response.data.message);
      });
  }

  return (
    <>
      <h2 className="CheckIn__subtitle">Seminarios</h2>
      {loadedData && <p style={{ color: 'red' }}>{loadedData}</p>}
      {seminaryEvent.map((event) => (
        <AttendanceModal
          changedDate={changedDate}
          kind={'seminary'}
          loading={loading}
          set_changedDate={set_changedDate}
          title={event.title}
          event={event.event}
          openModal={openModal}
          content={event.content}
          loadedData={loadedData}
          canCreate={event.canCreate}
          set_openModal={set_openModal}
          eventDates={props.eventDates}
          updateAttendance={updateAttendance}
          createAttendance={createAttendance}
          resetForm={props.resetForm || function () {}}
        />
      ))}
    </>
  );
};
export default SeminaryCheckin;
