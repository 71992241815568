import { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Button, Input, Modal } from 'semantic-ui-react';
import authServiceInstance from '../../services/auth';
import { useEffect } from 'react';
import { localhostname } from '.';

const ExpressLogin = (props) => {
  const [inputs, setInputs] = useState({});
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [errorData, set_errorData] = useState(null);

  useEffect(() => {
    setInputs({ email: props.email });
  }, [props.email]);

  const handleInputs = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setInputs({ ...inputs, [name]: value });
  };

  function login(e) {
    e.preventDefault();
    const { email, password, captcha } = inputs;

    if (!captcha && window.location.hostname !== localhostname) {
      set_errorData('Completa el captcha');
      return;
    }
    if (!email || !password) {
      set_errorData('Todos los campos son obligatorios');
      return;
    }
    setLoading(true);
    authServiceInstance
      .login({ email, password, captcha }, false)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setOpen(false);
          props?.onSuccessLogin?.();
          localStorage.setItem('userToken', response.data.token);
        }
      })
      .catch((error) => {
        if (error.response) set_errorData(error.response.data.message);
        setLoading(false);
      });
  }

  return (
    <Modal
      onClose={() => setOpen(false)}
      closeIcon
      onOpen={() => setOpen(true)}
      open={open}
      trigger={
        <Button basic color="black">
          Iniciar sesión
        </Button>
      }
    >
      <Modal.Header> Iniciar sesión</Modal.Header>
      <form onSubmit={(e) => login(e)} className="Registration__form">
        <p style={{ margin: 0 }}>
          Escribe {!props.email && 'el usuario y'} la contraseña {!!props.email && 'de'}{' '}
          {props.email} para continuar:
        </p>
        <div style={{ margin: '20px auto' }} className={'captcha'}>
          {!props.email && (
            <Input
              placeholder="Usuario"
              onChange={handleInputs}
              name={'email'}
              value={inputs.email}
              type="email"
            />
          )}
          <Input
            placeholder="Contraseña"
            onChange={handleInputs}
            name={'password'}
            value={inputs.password}
            type="password"
          />
          {errorData && (
            <label style={{ display: 'block', padding: '20px 0', color: 'red' }}>{errorData}</label>
          )}
          {!loading && (
            <ReCAPTCHA
              style={{ margin: 'auto' }}
              sitekey={process.env.REACT_APP_RECAPTCHA}
              onChange={(value) => setInputs({ ...inputs, captcha: value })}
            />
          )}
        </div>
        <Button disabled={loading} onClick={(e) => login(e)}>
          Entrar
        </Button>
      </form>
    </Modal>
  );
};

export default ExpressLogin;
