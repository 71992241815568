import React from 'react';
import GeneracionesService from '../../../../../services/generacionesService';
import Swal from 'sweetalert2';
import queryString from 'query-string';
import ParentsLogIn from './parentsLogIn';
import StepParentCreation from '../parentsMatch/stepParentCreation';
import StepParentMatchChildren from '../parentsMatch/stepParentMatchChildren';
import { GeneracionesParentComponent } from '../../../../../assets/styles/components/GeneracionesParent';

const ParentsReserve = (props) => {
  const [loading, set_loading] = React.useState(false);
  const [step, setStep] = React.useState(1);
  const [inputs, setInputs] = React.useState({});
  const [parent, set_parent] = React.useState({});
  const [generacionesGuests, set_generacionesGuests] = React.useState({});

  let query = props.location.search;
  let parsedQueryString = queryString.parse(query);

  function getInfo() {
    const { identification, phone } = inputs;
    if (!identification || !phone) return Swal.fire('Escribe la cedula tu cedula y tu telefono');

    set_loading(true);

    let body = { identification, phone };
    GeneracionesService.GeneracionesParentMatch(body)
      .then((response) => {
        if (response.data.success) {
          set_loading(false);
          set_parent(response.data.parent);
          set_generacionesGuests(response.data.GeneracionesGuests);
          if (response?.data?.parent?.identification) setStep(3);
          else setStep(2);
        }
      })
      .catch((error) => {
        Swal.fire(error?.response?.data?.message, error?.response?.data?.description);
        set_loading(false);
      });
  }

  React.useEffect(() => {
    setInputs({
      ...inputs,
      identification: parsedQueryString.identification,
      phone: parsedQueryString.phone,
    });
  }, []);

  const handleInputs = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    setInputs({ ...inputs, [name]: value });
  };

  const stepRender = () => {
    if (step === 1) {
      return (
        <ParentsLogIn
          parsedQueryString={parsedQueryString}
          setInputs={setInputs}
          inputs={inputs}
          getInfo={getInfo}
          leaders
          next="list"
          setStep={setStep}
          set_loading={set_loading}
          loading={loading}
        />
      );
    } else if (step === 2) {
      return (
        <StepParentCreation
          setInputs={setInputs}
          inputs={inputs}
          set_loading={set_loading}
          loading={loading}
          getInfo={getInfo}
          set_step={setStep}
          handleInputs={handleInputs}
        />
      );
    } else if (step === 3) {
      return (
        <StepParentMatchChildren
          parent={parent}
          children={generacionesGuests}
          set_step={setStep}
          getInfo={getInfo}
          setInputs={setInputs}
        />
      );
    } else {
      return <p>Cargando</p>;
    }
  };

  return (
    <GeneracionesParentComponent className="AdminTable">{stepRender()}</GeneracionesParentComponent>
  );
};

export default ParentsReserve;
