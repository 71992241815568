import proxybase from './proxybase';
import jwtDecode from 'jwt-decode';

class authService {
  login(body, redirect = true) {
    return proxybase
      .post('/auth/signin', body)
      .then((response) => {
        const lastLocation = sessionStorage.getItem('lastLocation');
        sessionStorage.removeItem('lastLocation');
        if (redirect) window.location.replace(lastLocation || '/');
        return Promise.resolve(response);
      })
      .catch((error) => {
        console.log(`An error occurred trying to loged in the user ${error}.`);
        return Promise.reject(error);
      });
  }
  getAllUsers() {
    return proxybase
      .post('/auth/getAllUsers')
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        console.log(`An error occurred trying to get all users ${error}.`);
        return Promise.reject(error);
      });
  }
  editUser(body) {
    return proxybase
      .put('/auth/editUser', body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  logout() {
    const lastLocation = window.location.pathname !== '/login' ? window.location.pathname : '/';
    localStorage.removeItem('userToken');
    sessionStorage.setItem('lastLocation', lastLocation);
    window.location.replace('/login');
  }
  getSession() {
    return localStorage.getItem('userToken') || '';
  }
  getCurrentUser() {
    try {
      return jwtDecode(this.getSession() || '');
    } catch {
      return {};
    }
  }
}
const authServiceInstance = new authService();
export default authServiceInstance;
