import React from 'react';
import { Button, Modal, TextArea, Header, Checkbox, Select } from 'semantic-ui-react';
import { boolSiNo, textArea } from '../../../components/helpers';
import moment from 'moment';
import membersForms from '../../../services/membersForms';
import Swal from 'sweetalert2/dist/sweetalert2.js';

const NewMemberModal = (props) => {
  const { set_showNewMembers, showNewMembers, newMember, changeNewMembersLine } = props;
  const {
    contacted,
    tutorObservations,
    Guest,
    country,
    comments,
    toPray,
    createdAt,
    updatedAt,
    interestedCrecer,
    interestedGroup,
    interestedInChurch,
  } = newMember;
  const { name, lastName, phone, email, identification, birthdate } = Guest;
  const [inputs, setInputs] = React.useState({});
  const [loading, set_loading] = React.useState(false);

  React.useEffect(() => {
    setInputs({
      interestedCrecer,
      interestedGroup,
      interestedInChurch,
      contacted,
      tutorObservations,
      toPray,
      comments,
    });
  }, [newMember]);

  const selectHandle = (a, { value, name }) => {
    setInputs({ ...inputs, [name]: value });
  };

  function updateNewMember() {
    let body = inputs;
    body.guest_id = Guest.id;

    set_loading(true);
    membersForms
      .updateNewMembers(body)
      .then((response) => {
        set_loading(false);
        if (response.data.success) {
          Swal.fire('Actualizado satisfatoriamente');
          set_showNewMembers(null);
          changeNewMembersLine(response.data.newMemberUpdated);
        }
      })
      .catch((error) => {
        set_loading(false);
        if (error.response) Swal.fire(error.response.data.message);
      });
  }

  const handleInputs = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    setInputs({ ...inputs, [name]: value });
  };

  const handleCheckers = (name) => setInputs({ ...inputs, [name]: !inputs[name] });

  return (
    <Modal open={showNewMembers === newMember.id} onClose={() => set_showNewMembers(null)}>
      <Modal.Content>
        <Modal.Description>
          <Header>Formulario de {name}</Header>
          <div className="NewMember-form">
            <p>
              <strong>Cedula: </strong>
              {identification}
            </p>
            <p>
              <strong>Nombre: </strong>
              {name}
            </p>
            <p>
              <strong>Apellido: </strong>
              {lastName}
            </p>
            {!!birthdate && (
              <p>
                <strong>Edad: </strong>
                {moment().diff(birthdate, 'years')}
              </p>
            )}
            <p>
              <strong>Celular: </strong>
              {phone}
            </p>
            {!!email && (
              <p>
                <strong>E-Mail: </strong>
                {email}
              </p>
            )}
            {!!country && (
              <p>
                <strong>Pais: </strong>
                {country}
              </p>
            )}
            <p>
              <strong>Fecha de llenado: </strong>
              {moment.tz(createdAt, 'America/Bogota').format('DD-MM-YYYY hh:mm a')}
            </p>
            <p>
              <strong>Fecha última interacción: </strong>
              {moment.tz(updatedAt, 'America/Bogota').format('DD-MM-YYYY hh:mm a')}
            </p>
          </div>
          <div className="form-tutor">
            <p style={{ margin: '20px 0 0 0', fontWeight: '800' }}>
              ¿Cómo fue tu experiencia el fin de semana? <br />
              <small>( 1 No me gusto, 5 Muy bueno, volveré) </small>
            </p>
            <Select
              placeholder="Calificacion de experiencia"
              style={{
                display: 'inline-block',
                width: '45%',
                margin: ' 7px 0',
              }}
              onChange={selectHandle}
              loading={loading}
              value={inputs.interestedInChurch}
              name="interestedInChurch"
              options={['1', '2', '3', '4', '5'].map((a) => ({
                key: a,
                value: a,
                text: a,
              }))}
            />
            <p style={{ margin: '20px 0 0 0', fontWeight: '800' }}>
              ¿Hay algo en lo que podemos orar por ti?
            </p>
            <Select
              placeholder="Solicitud de oración"
              style={{
                display: 'inline-block',
                width: '45%',
                margin: ' 7px 0',
              }}
              onChange={selectHandle}
              loading={loading}
              value={inputs.toPray}
              name="toPray"
              options={['Ninguna', 'Salud', 'Finanzas', 'Relaciones', 'Familias', 'Otras'].map(
                (a) => ({
                  key: a,
                  value: a,
                  text: a,
                })
              )}
            />
            <p style={{ margin: '20px 0 0 0', fontWeight: '800' }}>
              ¿Está interesado en asistir a un grupo?
            </p>
            <Select
              placeholder="Interesado en un grupo"
              style={{
                display: 'inline-block',
                width: '45%',
                margin: ' 7px 0',
              }}
              onChange={selectHandle}
              value={inputs.interestedGroup}
              loading={loading}
              name="interestedGroup"
              options={['Sí', 'No'].map((a) => ({ key: a, value: a, text: a }))}
            />
            <p style={{ margin: '20px 0 0 0', fontWeight: '800' }}>
              ¿Estás interesado en asistir a un encuentro?
            </p>
            <Select
              placeholder="Interesado en un encuentro"
              style={{
                display: 'inline-block',
                width: '45%',
                margin: ' 7px 0',
              }}
              onChange={selectHandle}
              loading={loading}
              value={inputs.interestedCrecer}
              name="interestedCrecer"
              options={['Sí', 'No'].map((a) => ({ key: a, value: a, text: a }))}
            />
            <p style={{ margin: '20px 0 0 0', fontWeight: '800' }}>Notas adicionales:</p>
            {textArea({
              TextArea,
              handler: handleInputs,
              type: 'text',
              label: 'Observaciones al contacto',
              name: `tutorObservations`,
              value: inputs[`tutorObservations`],
            })}
            <p style={{ margin: '20px 0 0 0', fontWeight: '800' }}>Finalización de llamada</p>
            <Checkbox
              label={`Marcar como contactado - ${boolSiNo(inputs[`contacted`])}`}
              name="contacted"
              checked={!!inputs[`contacted`]}
              toggle
              onClick={() => handleCheckers('contacted')}
            />
            <div style={{ margin: '20px 0' }}>
              <Button primary onClick={() => updateNewMember()}>
                Enviar
              </Button>
              <Button onClick={() => set_showNewMembers(null)}>Cerrar</Button>
            </div>
          </div>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};
export default NewMemberModal;
