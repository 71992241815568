import { Tab } from 'semantic-ui-react';
import './styles/DashboardCrecerComponent.js';
import { DashboardCrecerComponent } from './styles/DashboardCrecerComponent.js';
import { useContext } from 'react';
import { GuestDashboardContext } from './guestDashboardContext.js';
import { dayInTZ } from '../../helpers/index.js';

const Crecer = () => {
  const { crecerDates, guest } = useContext(GuestDashboardContext);
  const today = new Date();

  const attendanceStatus = (content, date) => {
    return (
      <span
        className={`status-indicator ${
          content?.[date.attendanceCode] ? 'status-true' : 'status-false'
        }`}
      >
        {content?.[date.attendanceCode] ? '✓' : '✕'}
      </span>
    );
  };

  const CrecerSteps = [
    {
      id: 1,
      name: `Paso 1 - <span>Encuentro</span>`,
      event: 'crecerConectar',
      stepsMade: guest.CrecerConectarSteps,
    },
    {
      id: 2,
      name: `Paso 2 - <span>Vida</span>`,
      event: 'crecerVida',
      stepsMade: guest.CrecerVidaSteps,
    },
    {
      id: 3,
      name: `Paso 3 - <span>Influencia</span>`,
      event: 'crecerInfluence',
      stepsMade: guest.CrecerInfluenceSteps,
    },
  ];

  const CrecerNextClasses = [
    {
      id: 1,
      name: `Vida`,
      event: 'crecerVida',
    },
    {
      id: 2,
      name: `Influencia`,
      event: 'crecerInfluence',
    },
  ];

  const getSeminaryClasses = (seminary) => {
    return guest.Seminaries?.find((attendance) => attendance.seminary === seminary);
  };

  const Seminarios = [
    {
      id: 1,
      name: `<span>Para Siempre</span>`,
      event: 'ParaSiempre',
      stepsMade: getSeminaryClasses('ParaSiempre'),
    },
    {
      id: 2,
      name: `<span>Proyecto Matrimonio</span>`,
      event: 'ProyectoMatrimonio',
      stepsMade: getSeminaryClasses('ProyectoMatrimonio'),
    },
    {
      id: 3,
      name: `<span>Escuela de héroes</span>`,
      event: 'EscueladeHeroes',
      stepsMade: getSeminaryClasses('EscueladeHeroes'),
    },
    {
      id: 4,
      name: `<span>Relaciones Sanas</span>`,
      event: 'RelacionesSanas',
      stepsMade: getSeminaryClasses('RelacionesSanas'),
    },
    {
      id: 5,
      name: `<span>Transformación Empresarial</span>`,
      event: 'TransformacionEmpresarial',
      stepsMade: getSeminaryClasses('TransformacionEmpresarial'),
    },
  ];

  const panes = [
    {
      menuItem: 'Crecer',
      render: () => (
        <Tab.Pane className="custom-pane">
          <div className="columns">
            {!!crecerDates.length &&
              [CrecerSteps[0]].map((step) => (
                <div className="column" key={step.event}>
                  <h3
                    style={{ fontWeight: 500, color: '#00c47a' }}
                    dangerouslySetInnerHTML={{ __html: step.name }}
                  />
                  {!!step?.stepsMade?.[0]?.percentage && (
                    <>
                      <li className="step-item title virtual">
                        <span>Virtual</span>
                        <span className="percentage">{step?.stepsMade?.[0]?.percentage || 0}%</span>
                      </li>
                      <li className="step-item title">
                        <span>Presencial</span>
                        <span />
                      </li>
                    </>
                  )}
                  <ul>
                    {crecerDates
                      ?.filter((d) => d.event === 'crecerEncuentro')
                      ?.map((date) => (
                        <li className="step-item encuentro" key={date.id}>
                          <span>{date.attendanceName}</span>
                          <span
                            className={`status-indicator ${
                              guest?.CrecerEncuentroSteps?.[0]?.date
                                ? 'status-true'
                                : 'status-false'
                            }`}
                          >
                            {guest?.CrecerEncuentroSteps?.[0]?.date ? '✓' : '✕'}
                          </span>
                        </li>
                      ))}
                    {crecerDates
                      ?.filter((d) => d.event === step.event)
                      ?.map((date) => (
                        <li key={date.id} className="step-item">
                          <span>{date.attendanceName}</span>
                          {attendanceStatus(step.stepsMade?.[0], date)}
                        </li>
                      ))}
                  </ul>
                </div>
              ))}
            {!!crecerDates.length &&
              [CrecerSteps[1], CrecerSteps[2]].map((step) => (
                <div className="column" key={step.event}>
                  <h3
                    style={{ fontWeight: 500, color: '#00c47a' }}
                    dangerouslySetInnerHTML={{ __html: step.name }}
                  />
                  <ul>
                    {!!step?.stepsMade?.[0]?.percentage && (
                      <>
                        <li className="step-item title virtual">
                          <span>Virtual</span>
                          <span className="percentage">
                            {step?.stepsMade?.[0]?.percentage || 0}%
                          </span>
                        </li>
                        <li className="step-item title">
                          <span>Presencial</span>
                          <span />
                        </li>
                      </>
                    )}
                    {crecerDates
                      ?.filter((d) => d.event === step.event)
                      ?.map((date) => (
                        <li key={date.id} className="step-item">
                          <span>{date.attendanceName}</span>
                          {attendanceStatus(step.stepsMade?.[0], date)}
                        </li>
                      ))}
                  </ul>
                </div>
              ))}
            {!!crecerDates.length &&
              CrecerNextClasses?.map((step) => (
                <div className="column next-classes" key={step.name}>
                  <h3 className="sticky-header">
                    <span style={{ color: '#191919' }}>Próximas Clases</span>
                    <span className="sub">{step.name}</span>
                  </h3>
                  <p className="attendance-sign">
                    Puedes asistir a las clases que te hagan falta en estas fechas:
                  </p>
                  <ul>
                    {crecerDates
                      ?.filter((date) => {
                        const attendanceDate = new Date(date.attendanceDate);
                        return attendanceDate >= today && date.event === step?.event;
                      })
                      .map((date) => (
                        <li
                          key={date.id}
                          style={{
                            marginBottom: '15px',
                            background: '#FFF',
                            borderRadius: '8px',
                            padding: '5px',
                            border: '1px solid #191919',
                          }}
                        >
                          <div style={{ fontWeight: '500' }}>{date.attendanceName}</div>
                          <div style={{ fontSize: '14px', color: '#767676' }}>
                            {dayInTZ(date.attendanceDate, 'MMMM DD [de] yyyy')}
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              ))}
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Seminarios',
      render: () => (
        <Tab.Pane className="custom-pane">
          <div className="columns">
            {!!Seminarios.length &&
              Seminarios.map((step) => (
                <div className="column" key={step.event}>
                  <h3
                    style={{ fontWeight: 500, color: '#ff776a' }}
                    dangerouslySetInnerHTML={{ __html: step.name }}
                  />
                  <ul>
                    {crecerDates
                      ?.filter((d) => d.event === step.event)
                      ?.map((date) => (
                        <li key={date.id} className="step-item">
                          <span>{date.attendanceName}</span>
                          {attendanceStatus(step.stepsMade, date)}
                        </li>
                      ))}
                  </ul>
                </div>
              ))}
          </div>
        </Tab.Pane>
      ),
    },
  ];

  return (
    <DashboardCrecerComponent>
      <h2 style={{ fontSize: '30px', color: '#5f5f5f', fontWeight: 400 }}>Tus pasos en</h2>
      <h2 className="title">Crecer & Seminarios</h2>
      <br></br>
      <Tab panes={panes} />
    </DashboardCrecerComponent>
  );
};

export default Crecer;
