import { useState, useEffect } from 'react';
import { DashboardGroupsComponent } from './styles/DashboardGroupsComponent';
import groupService from '../../../services/groupService';
import PieChartComponent from "../utils/PieChartComponent";
import {
  LabelList,
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';

const Groups = () => {
  const [groupedCategories, setGroupedCategories] = useState([]);
  const [groupedByActivityName, setGroupedByActivityName] = useState({});
  const [selectedCategoryData, setSelectedCategoryData] = useState([]);
  const [totalLeaders, setTotalLeaders] = useState(0);
  const [memberData, setMemberData] = useState([]);
  const [growthData, setGrowthData] = useState([]);
  const [totalMembers, setTotalMembers] = useState(0);
  const [totalGroup, setTotalGroup] = useState(0);
  const [onlineStatusData, setOnlineStatusData] = useState([]);
  const [groupStatusData, setGroupStatusData] = useState([]);
  const [genderCategory, setGenderCategory] = useState([]);
  const [teamCategory, setTeamCategory] = useState([]);
  const [leaderTrainer, setLeaderTrainer] = useState([]);
  const [segAge, setSegAge] = useState([]);
  const [segAgeAll, setSegAgeAll] = useState([]);
  const [category, setCategory] = useState('');
  const [loading, setLoading] = useState(true);
  const translationMap = {
    "Mixtos_Adultos_(26_a_40_años)": "Adultos (26 a 55)",
    "Parejas_(Casadas/_Unión_Libre_)": "Adultos (26 a 55)",
    "Mixtos_Adultos_(41_años_en_adelante)": "Adultos (26 a 55)",
    "Niños_Nxt_(10_a_13_años)": "Niños(7 a 13)",
    "Hombres_jóvenes_Youth_(18-25_años)": "Jóvenes (18 a 25)",
    "Chicos_Cyphers_(14_a_17_años)": "Adolescentes(14 a 17)",
    "Mujeres_Adultas_(46_a_55_años)": "Adultos (26 a 55)",
    "Youth_Mixto_(18_-_25_años)": "Jóvenes (18 a 25)",
    "Niños_79_(7_a_9_años)": "Niños(7 a 13)",
    "Mixto_79_(7_a_9_años)": "Niños(7 a 13)",
    "Mujeres_Jóvenes_Adultas_(26_a_35_años)": "Adultos (26 a 55)",
    "Hombres_Adultos_(46_a_55_años)": "Adultos (26 a 55)",
    "Chicas_Cyphers_(14_a_17_años)": "Adolescentes(14 a 17)",
    "Mujeres_Adultas_(36_a_45_años)": "Adultos (26 a 55)",
    "Niñas_Nxt_(10_a_13_años)": "Niños(7 a 13)",
    "Mujeres_jóvenes_Youth_(18-25_años)": "Jóvenes (18 a 25)",
    "Mujeres_Adultas_(Más_de_56_años)": "Adulto Mayor (mayor 55)",
    "Niñas_79_(7_a_9_años)": "Niños(7 a 13)",
    "Mixto_Nxt_(10_a_13_años)": "Niños(7 a 13)",
    "Hombres_Adultos_(36_a_45_años)": "Adultos (26 a 55)",
    "Hombres_Adultos_(26_a_35_años)": "Adultos (26 a 55)",
    "Niños(7 a 13)": "Niños(7 a 13)",
    "Mixto_jovenes_youth": "Jóvenes (18 a 25)",
    "Mixto_Jovenes": "Jóvenes (18 a 25)",
    "Mixto_Adultos": "Adultos (26 a 55)",
    "Jóvenes (18 a 25)": "Jóvenes (18 a 25)",
    "Chicos_Jovenes": "Jóvenes (18 a 25)",
    "Chicas_jovenes_youth": "Jóvenes (18 a 25)",
    "Chicas_Jovenes": "Jóvenes (18 a 25)",
    "Adultos (26 a 55)": "Adultos (26 a 55)",
    "Adulto Mayor (mayor 55)": "Adultos (26 a 55)",
    "Hombres": "Adultos (26 a 55)",
    "Mujeres": "Adultos (26 a 55)",
  };


  useEffect(() => {
    setLoading(true);
    const body = {
      noFilter: true,
    };

    groupService.getAllGroupsByLeader(body)
      .then((response) => {
        const groups = response.data.groups;

        const grouped = groups.reduce((acc, group) => {
          const activityName = group.activityName;
          const translatedName = translationMap[activityName] || activityName;

          if (!acc[translatedName]) {
            acc[translatedName] = [];
          }

          acc[translatedName].push(group);
          return acc;
        }, {});

        const list = Object.entries(grouped).map(([key, value]) => ({
          name: key,
          value: value.length,
          percentage: ((value.length / groups.length) * 100).toFixed(1),
          data: value
        }));

        const listA = Object.entries(grouped).map(([key, value]) => {
          const totalMembers = value.flatMap(group => group.GroupMembers || []);

          return {
            name: key,
            value: totalMembers.length,
            percentage: ((totalMembers.length / groups.reduce((sum, g) => sum + (g.GroupMembers?.length || 0), 0)) * 100).toFixed(1),
            data: totalMembers
          };
        });

        setSegAge(list)
        setSegAgeAll(listA)

        grouped["Todos"] = groups;


        setGroupedCategories(Object.keys(grouped));
        setGroupedByActivityName(grouped);
        setLoading(false);
      });

  }, []);

  const changeCategory = (event) => {
    setCategory(event.target.value);
    handleGenderCategory(event);
    handleTeamCategory(event);
    handleCategoryChange(event);
    handleCategoryChangeForMembers(event);
    handleCategoryChangeForGrowth(event);
    handleCategoryChangeForOnlineStatus(event);
    handleGroupStatusChange(event);
  };

  const handleTeamCategory = (event) => {
    const selectedCategory = event.target.value;

    if (selectedCategory) {
      const groups = groupedByActivityName[selectedCategory];
      const totalGroups = groups.length;

      // Inicializar los contadores y los arrays correspondientes
      const counts = {
        discipulado: { name: 'Equipo Discipulado', value: 0, data: [] },
        lideres: { name: 'Equipo Líderes', value: 0, data: [] },
      };

      groups.forEach((group) => {
        if (group.activityName.includes('_(')) {
          // Equipo de discipulado
          counts.discipulado.value += 1;
          counts.discipulado.data.push(group);
        } else {
          // Equipo de líderes
          counts.lideres.value += 1;
          counts.lideres.data.push(group);
        }
      });

      const Leader = handleLeaderType(counts.discipulado.data);
      const trainer = handleLeaderType(counts.lideres.data);
      const totalLeaders = Leader.Value + trainer.Value;

      const LeaderTrainer = ['Lider de equipo', 'Entrenador'].map((name, index) => {
        const result = index === 0 ? Leader : trainer;
        return {
          name,
          value: result.Value,
          percentage: ((result.Value / totalLeaders) * 100).toFixed(1),
          data: result.Data
        };
      });


      setLeaderTrainer(LeaderTrainer)

      // Calcular el porcentaje y construir el resultado final
      const result = Object.values(counts).map((item) => ({
        name: item.name,
        value: item.value,
        percentage: ((item.value / totalGroups) * 100).toFixed(1),
        data: item.data,
      }));

      setTeamCategory(result);
    }
  };

  const handleLeaderType = (groups) => {
    let counter = 0;
    const data = [];

    groups.forEach((group) => {
      if (typeof group.femaleLeader === 'object' && group.femaleLeader !== null) {
        counter += 1;
        data.push(group);
      }
      if (typeof group.maleLeader === 'object' && group.maleLeader !== null) {
        counter += 1;
        data.push(group);
      }
    });

    return {
      Value: counter,
      Data: data
    };
  };




  const handleGenderCategory = (event) => {
    const selectedCategory = event.target.value;

    if (selectedCategory) {
      const groups = groupedByActivityName[selectedCategory];
      const totalGroups = groups.length;

      // Inicializar los contadores y los arrays correspondientes
      const counts = {
        hombres: { name: 'Hombres', value: 0, data: [] },
        mujeres: { name: 'Mujeres', value: 0, data: [] },
        mixtos: { name: 'Mixtos', value: 0, data: [] },
      };
      groups.forEach((group) => {
        const prefix = group.activityName.split('_')[0];

        if (prefix === "Hombres" || prefix === "Niños" || prefix === "Chicos") {
          counts.hombres.value += 1;
          counts.hombres.data.push(group);
        } else if (prefix === "Mujeres" || prefix === "Niñas" || prefix === "Chicas") {
          counts.mujeres.value += 1;
          counts.mujeres.data.push(group);
        } else if (prefix === "Mixto" || prefix === "Mixtos" || prefix === "Parejas" || prefix === "Youth") {
          counts.mixtos.value += 1;
          counts.mixtos.data.push(group);
        }
      });

      // Calcular el porcentaje y construir el resultado final
      const result = Object.values(counts).map((item) => ({
        name: item.name,
        value: item.value,
        percentage: ((item.value / totalGroups) * 100).toFixed(1),
        data: item.data,
      }));
      setGenderCategory(result);
    }
  };




  const handleCategoryChange = (event) => {
    const selectedCategory = event.target.value;
    if (selectedCategory) {
      const groups = groupedByActivityName[selectedCategory];

      const genderCounts = groups.reduce((acc, group) => {
        if (group.maleLeader && group.femaleLeader && !group.coupleleadered) {
        }

        if (group.maleLeader) {
          if (!acc[group.maleLeader.gender]) {
            acc[group.maleLeader.gender] = { count: 0, leaders: [] };
          }
          acc[group.maleLeader.gender].count += 1;
          acc[group.maleLeader.gender].leaders.push(group.maleLeader);
        }
        if (group.femaleLeader) {
          if (!acc[group.femaleLeader.gender]) {
            acc[group.femaleLeader.gender] = { count: 0, leaders: [] };
          }
          acc[group.femaleLeader.gender].count += 1;
          acc[group.femaleLeader.gender].leaders.push(group.femaleLeader);
        }
        return acc;
      }, {});

      const leaderCount = Object.values(genderCounts).reduce((sum, { count }) => sum + count, 0);
      const chartData = Object.entries(genderCounts).map(([gender, { count, leaders }]) => ({
        name: gender,
        value: count,
        percentage: ((count / leaderCount) * 100).toFixed(1),
        data: leaders
      }));

      setSelectedCategoryData(chartData);
      setTotalLeaders(leaderCount);
    } else {
      setSelectedCategoryData([]);
      setTotalLeaders(0);
    }
  };

  const handleCategoryChangeForMembers = (event) => {
    const selectedCategory = event.target.value;
    if (selectedCategory) {
      const groups = groupedByActivityName[selectedCategory];

      const memberGenderCounts = groups.reduce((acc, group) => {
        group.GroupMembers.forEach((member) => {
          const gender = member.male ? member.male.gender : member.female ? member.female.gender : null;
          if (gender) {
            if (!acc[gender]) {
              acc[gender] = { count: 0, members: [] };
            }
            acc[gender].count += 1;
            acc[gender].members.push(member);
          }
        });
        return acc;
      }, {});

      const memberCount = Object.values(memberGenderCounts).reduce((sum, { count }) => sum + count, 0);

      const chartData = Object.entries(memberGenderCounts).map(([gender, { count, members }]) => ({
        name: gender,
        value: count,
        percentage: ((count / memberCount) * 100).toFixed(1),
        data: members,
      }));


      setMemberData(chartData);
      setTotalMembers(memberCount);
    } else {
      setMemberData([]);
      setTotalMembers(0);
    }
  };

  const handleCategoryChangeForGrowth = (event) => {
    const selectedCategory = event.target.value;
    if (selectedCategory) {
      const groups = groupedByActivityName[selectedCategory];

      const growthData = {};
      groups.forEach((group) => {
        const creationDate = new Date(group.createdAt);
        const today = new Date();
        const startYear = creationDate.getFullYear();
        const startMonth = creationDate.getMonth();
        const endYear = today.getFullYear();
        const endMonth = today.getMonth();

        for (let year = startYear; year <= endYear; year++) {
          for (
            let month = year === startYear ? startMonth : 0;
            month <= (year === endYear ? endMonth : 11);
            month++
          ) {
            const key = `${year}-${month < 10 ? `0${month}` : month}`;

            if (!growthData[key]) {
              growthData[key] = 0;
            }
            growthData[key] += group.GroupMembers.length;
          }
        }
      });

      const chartData = Object.entries(growthData)
        .map(([key, count]) => {
          const [year, month] = key.split('-');
          return {
            name: `${year}-${parseInt(month, 10) + 1}`,
            cant: count,
          };
        })
        .sort((a, b) => {
          const [yearA, monthA] = a.name.split('-').map(Number);
          const [yearB, monthB] = b.name.split('-').map(Number);
          return yearA === yearB ? monthA - monthB : yearA - yearB;
        });

      setGrowthData(chartData);
    } else {
      setGrowthData([]);
    }
  };

  const handleCategoryChangeForOnlineStatus = (event) => {
    const selectedCategory = event.target.value;
    if (selectedCategory) {
      const groups = groupedByActivityName[selectedCategory];

      const statusCounts = groups.reduce((acc, group) => {
        const statusKey = group.online ? 'Online' : 'Presencial';
        if (!acc[statusKey]) {
          acc[statusKey] = { count: 0, objects: [] };
        }
        acc[statusKey].count += 1;
        acc[statusKey].objects.push(group);

        return acc;
      }, {});

      const total = Object.values(statusCounts).reduce((sum, count) => sum + count.count, 0);
      const chartData = Object.entries(statusCounts).map(([status, { count, objects }]) => ({
        name: status,
        value: count,
        percentage: ((count / total) * 100).toFixed(1),
        data: objects
      }));

      setOnlineStatusData(chartData);
    } else {
      setOnlineStatusData([]);
    }
  };

  const handleGroupStatusChange = (event) => {
    const selectedCategory = event.target.value;
    if (selectedCategory) {
      const groups = groupedByActivityName[selectedCategory];

      const statusCounts = groups.reduce((acc, group) => {
        const isSuspended = group.suspended;
        if (isSuspended) {
          acc['Cerrados'] = (acc['Cerrados'] || 0) + 1;
        } else {
          acc['Abiertos'] = (acc['Abiertos'] || 0) + 1;
        }
        return acc;
      }, {});

      const totalGroups = Object.values(statusCounts).reduce((sum, count) => sum + count, 0);
      const chartData = Object.entries(statusCounts).map(([status, count]) => ({
        name: status,
        cant: count,
        percentage: ((count / totalGroups) * 100).toFixed(1)
      }));

      setGroupStatusData(chartData);
      setTotalGroup(totalGroups);
    } else {
      setGroupStatusData([]);
    }
  };


  return (
    <DashboardGroupsComponent>
      {loading ? (
        <div className='containerSpinner'>
          <div className='spinner'></div>
        </div>
      ) : (
        <>
          <h2 className='h2Title'>Grupos</h2>

          <div className="container">
            <h4 className='h4Title'>Seleccione una categoría</h4>
            <select
              onChange={changeCategory}
              className='selectCategory'
            >
              <option value="">Ninguna</option>
              {groupedCategories.map((category, index) => (
                <option key={index} value={category}>
                  {category}
                </option>
              ))}
            </select>
          </div>

          <div className="container-grafic">

            {category === 'Todos' && (
              <div className="grafic">
                <PieChartComponent
                  title="Discipulos en Generaciones"
                  data={segAgeAll}
                  width={500}
                  height={500}
                />
              </div>
            )}

            {category === 'Todos' && (
              <div className="grafic">
                <PieChartComponent
                  title="Grupos en Generaciones"
                  data={segAge}
                  width={500}
                  height={500}
                />
              </div>
            )}


            <div className="grafic">
              <PieChartComponent
                title="tipo de Lider"
                data={leaderTrainer}
                width={500}
              />
            </div>

            <div className="grafic">
              <PieChartComponent
                title="tipo de equipo"
                data={teamCategory}
                width={500}
              />
            </div>

            <div className="grafic">
              <PieChartComponent
                title="tipo de grupo"
                data={genderCategory}
                width={500}
              />
            </div>


            <div className="grafic">
              {selectedCategoryData.length > 0 && (
                <>
                  <PieChartComponent
                    title="Líderes Totales"
                    total={totalLeaders}
                    data={selectedCategoryData}
                  />
                </>
              )}
            </div>

            <div className="grafic">
              {memberData.length > 0 && (
                <>
                  <PieChartComponent
                    title="Discípulos Totales"
                    total={totalMembers}
                    data={memberData}
                    width={450}
                  />
                </>
              )}
            </div>

            <div className="grafic">
              <h3>Crecimiento 2019-2024</h3>
              <LineChart width={600} height={300} data={growthData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="cant" stroke="#f65d0a" />
              </LineChart>
            </div>

            <div className="grafic">
              <PieChartComponent
                title="Modalidades"
                data={onlineStatusData}
              />
            </div>

            <div className="grafic">
              <h3>Estado de Grupos: {totalGroup}</h3>
              <BarChart width={600} height={300} data={groupStatusData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="cant" fill="#ffb290">
                  <LabelList dataKey={(entry) => `${entry.cant} (${entry.percentage}%)`} position="top" />
                </Bar>
              </BarChart>
            </div>

            <div className="grafic">
              <h3>Tipo de Líderes</h3>

            </div>

          </div>
        </>
      )}
    </DashboardGroupsComponent>
  );
};

export default Groups;
