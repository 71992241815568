import { Select, Input } from 'semantic-ui-react';
import {
  age,
  avoCaptConstructor,
  churchMinistries,
  comunidadProcess,
  errorInput,
  eventSlugnameTrim,
  formInput,
  formSelectService,
  s,
  sortArray,
} from '../../../../../components/helpers';
import { spouseGender } from '../../reservation';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import EventService from '../../../../../services/eventService';

export const ReservationExtraInfo = (props) => {
  const { inputs, handleInputs, selectHandle, errorInputs, defaulteventSlugname, event } = props;

  const notUpdatedGroups = () =>
    inputs?.groupLeader?.filter?.((group) => moment().diff(group.updatedAt, 'days') >= 2);

  return (
    <>
      {(defaulteventSlugname().includes('deres') || defaulteventSlugname().includes('Navidad')) && (
        <div>
          <label
            htmlFor="perfil_de_lider"
            style={{
              margin: 0,
              color: errorInputs?.perfil_de_lider ? 'red' : '',
              display: 'block',
            }}
          >
            Selecciona tu perfil de lider
          </label>
          <Select
            placeholder={'Perfil de Lider'}
            className={errorInputs?.perfil_de_lider ? 'error' : ''}
            onChange={selectHandle}
            style={{ margin: '0 0 20px 0' }}
            value={inputs?.perfil_de_lider}
            name={'perfil_de_lider'}
            options={[
              'Entrenador',
              'Líder de Grupo',
              ...(defaulteventSlugname().includes('nmersion')
                ? []
                : ['Facilitador de Seminario', 'Voluntario', 'Staff']),
            ].map((a) => {
              return { key: a, value: a, text: a };
            })}
          />
        </div>
      )}
      {defaulteventSlugname().includes('ncuentro') && (
        <>
          <div style={{ margin: '0 0 20px 0' }}>
            {formSelectService({
              Select,
              handler: selectHandle,
              label: '¿Asistes a un grupo?',
              name: 'asistes_a_grupo',
              value: inputs.asistes_a_grupo || '',
              errorInputs,
              options: ['Sí', 'No'],
            })}
          </div>
          <div style={{ margin: '0 0 20px 0' }}>
            <label htmlFor="nombre_de_contacto" style={{ display: 'block' }}>
              Nombre de un familiar o amigo
            </label>
            {formInput({
              Input,
              handler: handleInputs,
              label: `Nombre de un familiar o amigo`,
              name: `nombre_de_contacto`,
              value: inputs[`nombre_de_contacto`],
              errorInputs,
            })}
          </div>
          <div style={{ margin: '0 0 20px 0' }}>
            <label htmlFor="parentesco_de_contacto" style={{ display: 'block' }}>
              Parentesco del familiar o amigo
            </label>
            {formInput({
              Input,
              handler: handleInputs,
              label: `Parentesco del familiar o amigo`,
              name: `parentesco_de_contacto`,
              value: inputs[`parentesco_de_contacto`],
              errorInputs,
            })}
          </div>
          <div style={{ margin: '0 0 20px 0' }}>
            <label htmlFor="telefono_de_contacto" style={{ display: 'block' }}>
              Telefono del familiar o amigo
            </label>
            {formInput({
              Input,
              handler: handleInputs,
              label: `Telefono del familiar o amigo`,
              name: `telefono_de_contacto`,
              type: 'number',
              value: inputs[`telefono_de_contacto`],
              errorInputs,
            })}
          </div>
        </>
      )}
      {((defaulteventSlugname().includes('eminario') &&
        (defaulteventSlugname().includes('mor') || defaulteventSlugname().includes('atrimonio'))) ||
        (defaulteventSlugname().includes('trenamiento') &&
          defaulteventSlugname().includes('uevos'))) && (
        <>
          <div style={{ margin: '0 0 20px 0' }}>
            {formSelectService({
              Select,
              handler: selectHandle,
              label: 'Estado civil',
              name: 'civil',
              value: inputs.civil || '',
              errorInputs,
              options: [
                'Union libre',
                ...(defaulteventSlugname().includes('eminario')
                  ? ['Casado por lo civil', 'Novios']
                  : ['Casado']),
                'Soltero',
              ],
            })}
          </div>
          {!!inputs.civil &&
            inputs.civil !== 'Soltero' &&
            !(
              defaulteventSlugname().includes('trenamiento') &&
              defaulteventSlugname().includes('uevos')
            ) && (
              <div style={{ margin: '0 0 20px 0' }}>
                {formInput({
                  Input,
                  handler: handleInputs,
                  label: `Nombre de ${spouseGender(inputs.gender)}`,
                  name: `nombre_${spouseGender(inputs.gender)}`,
                  value: inputs[`nombre_${spouseGender(inputs.gender)}`],
                  errorInputs,
                })}
                {formInput({
                  Input,
                  handler: handleInputs,
                  label: `Cedula de ${spouseGender(inputs.gender)}`,
                  name: `cedula_${spouseGender(inputs.gender)}`,
                  value: inputs[`cedula_${spouseGender(inputs.gender)}`],
                  type: 'number',
                  errorInputs,
                })}
                {formInput({
                  Input,
                  handler: handleInputs,
                  label: `Edad de ${spouseGender(inputs.gender)}`,
                  name: `edad_${spouseGender(inputs.gender)}`,
                  value: inputs[`edad_${spouseGender(inputs.gender)}`],
                  type: 'number',
                  errorInputs,
                })}
                {formInput({
                  Input,
                  handler: handleInputs,
                  label: `Telefono de ${spouseGender(inputs.gender)}`,
                  name: `telefono_${spouseGender(inputs.gender)}`,
                  value: inputs[`telefono_${spouseGender(inputs.gender)}`],
                  errorInputs,
                })}
                {formInput({
                  Input,
                  handler: handleInputs,
                  label: `Email de ${spouseGender(inputs.gender)}`,
                  name: `email_${spouseGender(inputs.gender)}`,
                  value: inputs[`email_${spouseGender(inputs.gender)}`],
                  errorInputs,
                })}
              </div>
            )}
        </>
      )}
      {defaulteventSlugname().includes('Camp') && (
        <div style={{ margin: '0 0 20px 0' }}>
          {formSelectService({
            Select,
            handler: selectHandle,
            label: '¿Tienes alguna limitación fisica?',
            name: 'limitacion_fisica_bool',
            value: inputs.limitacion_fisica_bool || '',
            errorInputs,
            options: ['Si', 'No'],
          })}
          {inputs.limitacion_fisica_bool === 'Si' &&
            formInput({
              Input,
              handler: handleInputs,
              label: '¿Cuál?',
              name: 'limitacion_fisica',
              value: inputs.limitacion_fisica,
              errorInputs,
            })}
        </div>
      )}
      {defaulteventSlugname().includes('eminario') &&
        defaulteventSlugname().includes('elaciones') && (
          <>
            <div style={{ margin: '0 0 20px 0' }}>
              <label htmlFor="encuentro" style={{ display: 'block' }}>
                ¿Has asistido a un encuentro?
              </label>
              {formSelectService({
                Select,
                handler: selectHandle,
                label: '¿Has asistido a un encuentro?',
                name: 'encuentro',
                value: inputs.encuentro || '',
                errorInputs,
                options: ['Si', 'No'],
              })}
            </div>
            <div>
              <label htmlFor="veces_seminario" style={{ display: 'block' }}>
                ¿Cuantas veces has asistido al seminario?
              </label>
              {formInput({
                Input,
                handler: handleInputs,
                label: '¿Cuantas veces has asistido al seminario?',
                name: 'veces_seminario',
                value: inputs.veces_seminario,
                errorInputs,
                type: 'number',
              })}
            </div>
          </>
        )}
      {defaulteventSlugname().includes('eminario') && defaulteventSlugname().includes('eroes') && (
        <>
          <div style={{ margin: '0 0 20px 0' }}>
            {formSelectService({
              Select,
              handler: selectHandle,
              label: 'Selecciona como recibirás el seminario',
              name: 'recibe_seminario',
              value: inputs.recibe_seminario || '',
              errorInputs,
              options: [
                'Padres (en pareja)',
                'Madre Soltera',
                'Padre Soltero',
                'Abuelos (En pareja)',
                'Abuela',
                'Abuelo',
                'Soltero (no son padres)',
                'Otro',
              ],
            })}
            {inputs.recibe_seminario === 'Otro' &&
              formInput({
                Input,
                handler: handleInputs,
                label: 'Otro, ¿Cuál?',
                name: 'recibe_seminario_otro',
                value: inputs.recibe_seminario_otro,
                errorInputs,
              })}
          </div>
        </>
      )}
      {(defaulteventSlugname().includes('recer') ||
        (defaulteventSlugname().includes('eminario') &&
          defaulteventSlugname().includes('elaciones')) ||
        defaulteventSlugname().includes('ntrenamiento')) && (
        <>
          <div style={{ margin: '0 0 20px 0' }}>
            {formSelectService({
              Select,
              handler: selectHandle,
              label: '¿Tienes lider?',
              name: 'tienes_lider',
              value: inputs.tienes_lider || '',
              errorInputs,
              options: ['Si', 'No'],
            })}
            {inputs.tienes_lider === 'Si' && (
              <>
                {formInput({
                  Input,
                  handler: handleInputs,
                  label: 'Nombre de tu líder',
                  name: 'nombre_lider',
                  value: inputs.nombre_lider,
                  errorInputs,
                })}
                {formInput({
                  Input,
                  handler: handleInputs,
                  label: 'Telefono de tu líder',
                  name: 'telefono_lider',
                  value: inputs.telefono_lider,
                  errorInputs,
                })}
              </>
            )}
          </div>
          {defaulteventSlugname().includes('recer') && (
            <div>
              <label htmlFor="crecer_previo">¿Qué cursos has culminado de Crecer?</label>
              <p style={{ margin: 0 }}>
                <small>
                  <i>Puedes seleccionar varios</i>
                </small>
              </p>
              <Select
                placeholder={'Cuéntanos, ¿Qué cursos has culminado de Crecer? '}
                onChange={selectHandle}
                value={inputs.crecer_previo || ''}
                name={'crecer_previo'}
                id={'crecer_previo'}
                className={`Registration__input Registration__input-${'crecer_previo'} ${
                  errorInputs['crecer_previo'] ? 'error' : ''
                }`}
                fluid
                multiple
                selection
                options={comunidadProcess.map((opt) => {
                  return { key: opt, value: opt, text: eventSlugnameTrim(opt) };
                })}
              />
            </div>
          )}
          {defaulteventSlugname().includes('Crecer') &&
            defaulteventSlugname().includes('Presencial') && (
              <div>
                <label htmlFor="crecer_paso">A que paso de crecer te vas a inscribir</label>
                <Select
                  placeholder={'A que paso de crecer te vas a inscribir'}
                  onChange={selectHandle}
                  value={inputs.crecer_paso || ''}
                  name={'crecer_paso'}
                  id={'crecer_paso'}
                  className={`Registration__input Registration__input-${'crecer_paso'} ${
                    errorInputs['crecer_paso'] ? 'error' : ''
                  }`}
                  options={['Crecer_Vida', 'Crecer_Influencia'].map((opt) => {
                    return { key: opt, value: opt, text: eventSlugnameTrim(opt) };
                  })}
                />
              </div>
            )}
          {defaulteventSlugname().includes('3') && (
            <>
              <div style={{ margin: '0 0 20px 0' }}>
                {formSelectService({
                  Select,
                  handler: selectHandle,
                  label: '¿Eres bautizado en aguas?',
                  name: 'bautizado',
                  value: inputs.bautizado || '',
                  errorInputs,
                  options: ['Si', 'No', 'No, pero me gustaría'],
                })}
              </div>
              <div style={{ margin: '0 0 20px 0' }}>
                {formSelectService({
                  Select,
                  handler: selectHandle,
                  label: '¿Sirves actualmente en expriencia?',
                  name: 'sirve_en_experiencia',
                  value: inputs.sirve_en_experiencia || '',
                  errorInputs,
                  options: ['Si', 'No', 'No, pero me gustaría'],
                })}
                {inputs.sirve_en_experiencia === 'Si' && (
                  <Select
                    placeholder={'Area de Servicio'}
                    className={errorInputs['area_voluntario'] ? 'error' : ''}
                    onChange={selectHandle}
                    style={{ margin: '0 0 20px 0' }}
                    value={inputs['area_voluntario']}
                    name={'area_voluntario'}
                    options={sortArray(churchMinistries).map((a) => ({
                      key: a,
                      value: a,
                      text: a,
                    }))}
                  />
                )}
              </div>
            </>
          )}
        </>
      )}
      {defaulteventSlugname().includes('ntrenamiento') &&
        defaulteventSlugname().includes('deres') &&
        false && (
          <>
            {notUpdatedGroups()?.length ? (
              <div style={{ margin: '0 0 25px 0' }}>
                <p>
                  Te pedimos que por favor actualices la información de tu{s(notUpdatedGroups())}{' '}
                  grupo{s(notUpdatedGroups())} para continuar con el registro <br />
                  Actualiza haciendo clic en el nombre de tu grupo ⬇️
                </p>
                <p style={{ margin: '15px 0 5px 0' }}>
                  <strong>Última vez actualizado:</strong>
                </p>
                {notUpdatedGroups().map((group) => (
                  <p style={{ margin: '5px 0' }}>
                    <Link
                      to={`/grupos/lider?phone=${inputs.phone}&identification=${
                        inputs.identification
                      }&event=Entrenamiento_para_Lideres&activityName=${
                        group.activityName
                      }&ac=${avoCaptConstructor(inputs['identification'])}`}
                    >
                      <strong>{group.groupName}:</strong>{' '}
                      {moment(group.updatedAt)
                        .tz('America/Bogota')
                        .format('DD [de] MMMM [de] YYYY')}
                    </Link>
                  </p>
                ))}
                <p style={{ margin: '15px 0 5px 0' }}>
                  Una vez termines de actualizar, haz clic en el botón "Guardar y continuar
                  registro" en la edición de tú grupo.
                </p>
              </div>
            ) : (
              <p>¡No tienes grupos pendientes por ser actualizados!</p>
            )}
          </>
        )}
      {defaulteventSlugname().includes('ntrenamiento') &&
        defaulteventSlugname().includes('deres') &&
        formSelectService({
          Select,
          handler: selectHandle,
          label: '¿Tu grupo esta actualmente activo?',
          name: 'grupo_activo',
          value: inputs.grupo_activo || '',
          errorInputs,
          options: ['Si', 'No'],
        })}
      <div>
        {event?.additionalinformation?.extraInfo?.map((input) => {
          return (
            <>
              <label
                htmlFor={input.name}
                style={{ margin: 0, color: errorInputs[input.name] ? 'red' : '', display: 'block' }}
              >
                {input.label.replace(/_/g, ' ')} {!!errorInputs[input.name] && '(Campo requerido)'}
              </label>
              {formInput({
                Input,
                handler: handleInputs,
                label: input.label.replace(/_/g, ' '),
                name: input.name,
                value: inputs[input.name],
                errorInputs,
                type: input.type,
              })}
            </>
          );
        })}
      </div>
    </>
  );
};

export const verifyReservationForm = (event, defaulteventSlugname, inputs, set_errorInputs) => {
  let mandatoryInputs = [
    'name',
    'lastName',
    'email',
    'gender',
    'birthdate',
    'identification',
    'phone',
  ];

  if (event?.additionalinformation?.extraInfo) {
    event?.additionalinformation?.extraInfo.forEach((input) => {
      if (input.mandatory) {
        mandatoryInputs = [...mandatoryInputs, input.name];
      }
    });
  }

  if (age(inputs.birthdate) <= 14) {
    mandatoryInputs = [...mandatoryInputs, 'parentIdentification', 'parentFullname'];
  }

  if (defaulteventSlugname().includes('deres') || defaulteventSlugname() === 'Navidad') {
    mandatoryInputs = [...mandatoryInputs, 'perfil_de_lider'];
  }

  if (defaulteventSlugname().includes('eminario')) {
    if (defaulteventSlugname().includes('elaciones')) {
      mandatoryInputs = [...mandatoryInputs, 'tienes_lider', 'encuentro', 'veces_seminario'];
      if (inputs?.tienes_lider === 'Si') {
        mandatoryInputs = [...mandatoryInputs, 'nombre_lider', 'telefono_lider'];
      } else if (inputs?.tienes_lider === 'No') {
        mandatoryInputs = [...mandatoryInputs, 'Not_Complaint_Relaciones', 'Not_Complaint'];
      }
      if (inputs?.encuentro === 'No' || inputs?.veces_seminario >= 1) {
        mandatoryInputs = [...mandatoryInputs, 'Not_Complaint_Relaciones', 'Not_Complaint'];
      }
      if (
        (defaulteventSlugname().includes('ujeres') && inputs?.gender === 'Masculino') ||
        (defaulteventSlugname().includes('ombres') && inputs?.gender === 'Femenino')
      ) {
        mandatoryInputs = [...mandatoryInputs, 'Not_Complaint'];
        Swal.fire(
          `Este formulario es para ${
            inputs?.gender === 'Masculino' ? 'las mujeres' : 'los hombres'
          }`,
          `Por favor inscribete en el formulario de ${
            inputs?.gender === 'Femenino' ? 'las mujeres' : 'los hombres'
          }`
        );
      }
    }
    if (defaulteventSlugname().includes('eroes')) {
      mandatoryInputs = [...mandatoryInputs, 'recibe_seminario'];
    }
    if (
      defaulteventSlugname().includes('mor') ||
      defaulteventSlugname().includes('atrimonio') ||
      (defaulteventSlugname().includes('trenamiento') && defaulteventSlugname().includes('uevos'))
    ) {
      mandatoryInputs = [...mandatoryInputs, 'civil'];
      if (!!inputs.civil && inputs.civil !== 'Soltero') {
        mandatoryInputs = [...mandatoryInputs, `nombre_${spouseGender(inputs.gender)}`];
        mandatoryInputs = [...mandatoryInputs, `cedula_${spouseGender(inputs.gender)}`];
        mandatoryInputs = [...mandatoryInputs, `telefono_${spouseGender(inputs.gender)}`];
      }
    }
  }

  if (defaulteventSlugname().includes('ncuentro')) {
    mandatoryInputs = [
      ...mandatoryInputs,
      'asistes_a_grupo',
      'nombre_de_contacto',
      'parentesco_de_contacto',
      'telefono_de_contacto',
    ];
  }

  if (defaulteventSlugname().includes('Youth_Camp')) {
    mandatoryInputs = [...mandatoryInputs, 'limitacion_fisica_bool'];
    if (inputs.limitacion_fisica_bool === 'Si') {
      mandatoryInputs = [...mandatoryInputs, 'limitacion_fisica'];
    }
  }

  if (defaulteventSlugname().includes('recer') && defaulteventSlugname().includes('3')) {
    mandatoryInputs = [
      ...mandatoryInputs,
      'crecer_previo',
      'tienes_lider',
      'sirve_en_experiencia',
      'bautizado',
    ];
    if (inputs?.tienes_lider === 'Si') {
      mandatoryInputs = [...mandatoryInputs, 'nombre_lider', 'telefono_lider'];
    }
    if (inputs?.sirve_en_experiencia === 'Si') {
      mandatoryInputs = [...mandatoryInputs, 'area_voluntario'];
    }
  }

  if (defaulteventSlugname().includes('Crecer') && defaulteventSlugname().includes('Presencial')) {
    mandatoryInputs = [...mandatoryInputs, 'crecer_paso'];
  }

  let errorInputs = {};

  mandatoryInputs.forEach((input) => {
    errorInputs = errorInput({
      errorInputs,
      input: { name: input, value: inputs[input] },
    });
  });

  if (inputs?.crecer_previo) {
    errorInputs = errorInput({
      errorInputs,
      input: {
        name: 'crecer_previo',
        value: Boolean(inputs?.crecer_previo?.length > 0),
      },
    });
  }

  if (inputs?.identification && inputs?.identification?.length <= 5) {
    errorInputs = errorInput({
      errorInputs,
      input: {
        name: 'identification',
        value: inputs?.identification.length >= 5,
      },
    });
  }

  if (inputs?.phone && inputs?.phone?.length <= 10) {
    errorInputs = errorInput({
      errorInputs,
      input: {
        name: 'phone',
        value: inputs?.phone.length >= 10,
      },
    });
  }

  if (age(inputs.birthdate) < 4) {
    Swal.fire('Lo sentimos, solo está permitida la entrada desde los 4 años de edad.');
    errorInputs = {
      ...errorInputs,
      age: true,
    };
  }

  set_errorInputs(errorInputs);
  return Object.values(errorInputs)?.includes(true);
};

export const sendReservationHelper = ({
  inputs,
  event,
  eventSlugname,
  special,
  eventSelected = {},
}) => {
  let aditionalinformation = {};

  if (inputs.asistes_a_grupo) aditionalinformation.asistes_a_grupo = inputs.asistes_a_grupo;
  if (inputs.perfil_de_lider) aditionalinformation.perfil_de_lider = inputs.perfil_de_lider;
  if (inputs.veces_seminario) aditionalinformation.veces_seminario = inputs.veces_seminario;
  if (inputs.ciudad) aditionalinformation.ciudad = inputs.ciudad;
  if (inputs.limitacion_fisica) aditionalinformation.limitacion_fisica = inputs.limitacion_fisica;
  if (inputs.bautizado) aditionalinformation.bautizado = inputs.bautizado;
  if (inputs.nombre_lider) aditionalinformation.nombre_lider = inputs.nombre_lider;
  if (inputs.telefono_lider) aditionalinformation.telefono_lider = inputs.telefono_lider;
  if (inputs.civil) aditionalinformation.civil = inputs.civil;
  if (inputs.encuentro) aditionalinformation.encuentro = inputs.encuentro;
  if (inputs.grupo_activo) aditionalinformation.grupo_activo = inputs.grupo_activo;
  if (inputs.nombre_de_contacto)
    aditionalinformation.nombre_de_contacto = inputs.nombre_de_contacto;
  if (inputs.parentesco_de_contacto)
    aditionalinformation.parentesco_de_contacto = inputs.parentesco_de_contacto;
  if (inputs.telefono_de_contacto)
    aditionalinformation.telefono_de_contacto = inputs.telefono_de_contacto;
  if (inputs.tienes_lider) aditionalinformation.tienes_lider = inputs.tienes_lider;
  if (inputs.recibe_seminario) aditionalinformation.recibe_seminario = inputs.recibe_seminario;
  if (inputs.recibe_seminario_otro)
    aditionalinformation.recibe_seminario_otro = inputs.recibe_seminario_otro;
  if (inputs.crecer_previo) aditionalinformation.crecer_previo = inputs?.crecer_previo?.join(', ');
  if (inputs.crecer_paso) aditionalinformation.crecer_paso = inputs.crecer_paso;
  if (inputs.sirve_en_experiencia)
    aditionalinformation.sirve_en_experiencia = inputs.sirve_en_experiencia;
  if (inputs[`nombre_${spouseGender(inputs.gender)}`])
    aditionalinformation[`nombre_${spouseGender(inputs.gender)}`] =
      inputs[`nombre_${spouseGender(inputs.gender)}`];
  if (inputs[`cedula_${spouseGender(inputs.gender)}`])
    aditionalinformation[`cedula_${spouseGender(inputs.gender)}`] =
      inputs[`cedula_${spouseGender(inputs.gender)}`];
  if (inputs[`telefono_${spouseGender(inputs.gender)}`])
    aditionalinformation[`telefono_${spouseGender(inputs.gender)}`] =
      inputs[`telefono_${spouseGender(inputs.gender)}`];
  if (inputs[`email_${spouseGender(inputs.gender)}`])
    aditionalinformation[`email_${spouseGender(inputs.gender)}`] =
      inputs[`email_${spouseGender(inputs.gender)}`];

  if (event?.additionalinformation?.extraInfo) {
    event?.additionalinformation?.extraInfo.forEach((input) => {
      aditionalinformation = {
        ...aditionalinformation,
        [input.name]: inputs[input.name],
      };
    });
  }

  let service = { ...(inputs.service || {}), ...eventSelected };
  if (!service?.id && !inputs.serviceArea) {
    service = { ...service, ...event };
  }

  return EventService.MakeAReservation({
    ...inputs,
    aditionalinformation,
    eventSlugname,
    special,
    service,
  });
};
