import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../components/common/Button';
import authService from '../../services/auth';
import queryString from 'query-string';
import { changeDocTitle, chPerm } from '../../components/helpers';
import MultiUserModal from './components/multiuserModal';
import { HomeComponent } from '../../assets/styles/components/Home';

const RegisterModule = (props) => {
  const currentUser = authService.getCurrentUser();
  let query = props.location.search;
  let parsedQueryString = queryString.parse(query);

  React.useEffect(() => {
    changeDocTitle('Administrar');
  }, []);

  return (
    <>
      {false && currentUser.multiuser && <MultiUserModal />}
      <HomeComponent>
        <div className="content">
          <h1 className="title">Bienvenido</h1>
          <p className="intro">
            <strong style={{ display: 'block' }}>¡Hola {currentUser.name}!</strong>
            Bienvenido a la aplicación web de Comunidad.
          </p>
        </div>
        <div className="buttons">
          {chPerm(['events']) && (
            <Link to="/registration">
              <Button text="Registro nuevo" disabled={false} />
            </Link>
          )}
          {chPerm(['crecer']) && (
            <Link
              to={`/checkin?event=crecer${
                parsedQueryString.id ? `&id=${parsedQueryString.id}` : ''
              }`}
            >
              <Button text="Ingreso crecer" disabled={false} />
            </Link>
          )}
          {chPerm(['events']) && (
            <Link
              to={`/checkin?event=Auditorio_Principal&eventSN=Auditorio_Principal${
                parsedQueryString.id ? `&id=${parsedQueryString.id}` : ''
              }`}
            >
              <Button text="Ingreso Servicio" disabled={false} />
            </Link>
          )}
          {chPerm(['events']) && (
            <Link to="/checkin/print-queue">
              <Button text="Cola de impresión" disabled={false} />
            </Link>
          )}
          {chPerm(['generaciones']) && (
            <Link to="/servicio/generaciones">
              <Button text="Ingreso Generaciones" disabled={false} />
            </Link>
          )}
          {chPerm(['seminary'], true) && (
            <Link to="/grupos/lider?kindgroup=Grupo_Seminario">
              <Button text="Asistencia grupos seminario" disabled={false} />
            </Link>
          )}
          {chPerm(['salaExp', 'experienceCoord', 'volunteers']) && (
            <Link to="/voluntarios-servicio">
              <Button text="Ingreso voluntarios" disabled={false} />
            </Link>
          )}
        </div>
        {currentUser.profile === 1 && (
          <Link to="/admin">
            <p className="link">Administrar</p>
          </Link>
        )}
      </HomeComponent>
    </>
  );
};

export default RegisterModule;
