import React from 'react';
import GroupRow from '../../../components/common/adminTable/GroupRow';
import moment from 'moment';
import { weekdays, eventSlugnameTrim, avoCaptConstructor } from '../../helpers';
import AddGroupMember from './addMember';
import { Button } from 'semantic-ui-react';
import ViewCoupleGroup from '../../../containers/admin/groups/view-couple-group';
import ViewGroupMembers from './viewGroupMembers';
import PendingNewMembers from './pendingNewMembers';
import SetSeminaryGroupAttendance from '../../crecer/setSeminaryGroupAttendance';

const GroupView = (props) => {
  const { inputs, loading, kindgrouplist, group } = props;
  const [openNewMemberModal, setOpenNewMemberModal] = React.useState(false);
  const [openMembers, setOpenMembers] = React.useState(false);
  const [openEditGroupModal, setOpenEditGroupModal] = React.useState(false);
  const [openPendingNewMembersModal, setOpenPendingNewMembersModal] = React.useState(false);

  const isCouples =
    (inputs.activityName && inputs.activityName.includes('Parejas')) || inputs.coupleleadered;

  const activeMembers = group.GroupMembers?.filter((member) => !member.pending);
  const pendingMembers = group.GroupMembers?.filter((member) => member.pending);

  const getInfo = () => props.getInfo({ ac: avoCaptConstructor(inputs.identification) });

  return (
    <>
      <div className="AdminTable__content">
        {inputs.groups.length === 1 && <h3 style={{ margin: '20px 0 0 0' }}>{group.groupName}</h3>}
        <p style={{ fontWeight: 'bold' }}>{eventSlugnameTrim(group.activityName)}</p>
        <p>
          <strong>Dia:</strong> {weekdays[group.dayOfWeek]} <strong>Hora:</strong>{' '}
          {moment(group.time, 'HH:mm').format('hh:mm a z')}
        </p>
        <div>
          <Button onClick={() => setOpenNewMemberModal(true)}>Agregar participante</Button>
          {openNewMemberModal && (
            <AddGroupMember
              setOpen={setOpenNewMemberModal}
              group={group}
              isCouples={isCouples}
              getInfo={getInfo}
            />
          )}
          {group?.activityName.includes('._') ? (
            <>
              <Button primary onClick={() => setOpenMembers(true)}>
                Ver y marcar asistencia
              </Button>
              {openMembers && (
                <SetSeminaryGroupAttendance
                  group={group}
                  setOpen={setOpenMembers}
                  getInfo={getInfo}
                  activeMembers={activeMembers}
                />
              )}
            </>
          ) : (
            <>
              <Button onClick={() => setOpenMembers(true)}>Ver info. de miembros</Button>
              {openMembers && (
                <ViewGroupMembers
                  setOpen={setOpenMembers}
                  group={group}
                  groupsLeaders={inputs.groupsLeaders}
                  isCouples={isCouples}
                  getInfo={getInfo}
                  activeMembers={activeMembers}
                />
              )}
            </>
          )}

          <ViewCoupleGroup
            getInfo={getInfo}
            open={openEditGroupModal}
            setOpen={setOpenEditGroupModal}
            editing
            editingInputs={group}
            isCouples={isCouples}
            activeMembers={activeMembers}
            match={props.match}
            kindgrouplist={kindgrouplist}
          />
          {!!pendingMembers?.length && (
            <Button color="black" onClick={() => setOpenPendingNewMembersModal(true)}>
              Solicitudes
            </Button>
          )}
          {openPendingNewMembersModal && (
            <PendingNewMembers
              group={group}
              activeMembers={activeMembers}
              setOpen={setOpenPendingNewMembersModal}
              pendingMembers={pendingMembers}
              isCouples={isCouples}
              getInfo={getInfo}
            />
          )}
        </div>
      </div>

      <table>
        <thead>
          <tr>
            {isCouples && (
              <>
                <th>Teléfono El</th>
                <th>Nombre El</th>
              </>
            )}
            <th>Teléfono {isCouples && 'Ella'}</th>
            <th>Nombre {isCouples && 'Ella'}</th>
            {group.takeattendance && <th>Asistencia</th>}
            <th>Ver</th>
          </tr>
        </thead>
        {inputs.groups && group && activeMembers?.length === 0 && !loading && (
          <p>
            Tu grupo ya quedó registrado. Te invitamos a que registres los miembros de tu grupo.
          </p>
        )}
        {loading && <p>Cargando</p>}
        <tbody>
          {activeMembers.map((member) => (
            <GroupRow
              member={member}
              takeattendance={group.takeattendance}
              getInfo={getInfo}
              isCouples={isCouples}
            />
          ))}
        </tbody>
      </table>
    </>
  );
};

export default GroupView;
